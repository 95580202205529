<script setup lang="ts">
import { useMiscModalStore } from '~/stores/miscModal'

import { hasSlotContent } from '~/utils/hasSlot'

type Props = {
  // Indicates the absence of the normal "floating" display of the modal
  noFloat?: boolean
  // Use this to replace "value" and $emit('input')
  modelValue: boolean
  contentClass?: string
  contentId?: string
  title?: string
  bottomFiller?: boolean
  /**
   * This boolean is used to keep the content of the modal in the DOM + Vue tree even when we decide to close the modal.
   */
  keepSlotMounted?: boolean
  hasCancel?: boolean
  hasClose?: boolean
  noContentPadding?: boolean
  preventMaskClickClose?: boolean
  overrideContentMaxHeightOffset?: number
  forceMobile?: boolean
  modalId?: string
}
type Emits = {
  cancel: []
  close: []
  'update:modelValue': [payload: boolean]
}
type Slots = {
  'custom-header'(): any
  default(): any
  title(): any
}

const props = withDefaults(defineProps<Props>(), {
  noFloat: false,
  contentClass: '',
  contentId: undefined,
  title: '',
  bottomFiller: false,
  keepSlotMounted: false,
  hasCancel: false,
  hasClose: true,
  preventMaskClickClose: false,
  overrideContentMaxHeightOffset: 0,
  forceMobile: false,
  modalId: '',
})
const emit = defineEmits<Emits>()
defineSlots<Slots>()
const slots = useSlots()

const contentTemplateRef = ref<HTMLElement | null>(null)
const { OPEN, CLOSE } = useMiscModalStore()

const hasCustomHeaderSlot = computed<boolean>(() =>
  hasSlotContent(slots['custom-header']),
)
const hasTitleSlot = computed<boolean>(() => hasSlotContent(slots.title))
const hasTitle = computed(() => {
  return (props.title?.length ?? 0) > 0 || hasTitleSlot.value
})

const contentStyle = computed(() => {
  if (props.overrideContentMaxHeightOffset) {
    return {
      maxHeight: `calc(100vh - ${props.overrideContentMaxHeightOffset}px)`,
      height: '100%',
    }
  } else {
    return {}
  }
})

watch(
  () => props.modelValue,
  (isOpen) => {
    registerModalToggle(isOpen ? OPEN : CLOSE)
  },
)

onMounted(() => {
  if (props.modelValue !== true) return

  registerModalToggle(OPEN)
})

onBeforeUnmount(() => {
  if (!props.modelValue) return

  registerModalToggle(CLOSE)
})

function registerModalToggle(action: () => void): void {
  if (!props.noFloat) action()
}

function close(): void {
  emit('update:modelValue', false)
  emit('close')
}

function maskClose(): void {
  if (!props.preventMaskClickClose) close()
}
function scrollToEnd(): void {
  nextTick(() => {
    if (!contentTemplateRef.value) return

    contentTemplateRef.value.scrollTo({
      top: contentTemplateRef.value.scrollHeight,
      behavior: 'smooth',
    })
  })
}

defineExpose({ scrollToEnd })
</script>

<template>
  <transition name="fade" mode="out-in">
    <div
      v-show="modelValue"
      v-if="keepSlotMounted || modelValue"
      :class="{ noFloat }"
      class="maskPopup"
      role="dialog"
      data-test-id="modal-mask"
      @mousedown.stop="maskClose"
    >
      <div
        class="wrapper"
        :class="[{ hasTitle, forceMobile }, modalId]"
        @mousedown.stop
      >
        <slot v-if="hasCustomHeaderSlot" name="custom-header" />
        <div v-if="hasTitle || hasClose" :class="{ title: hasTitle }">
          <div
            v-if="hasCancel"
            class="previousButton iconBtn"
            @mousedown.stop="$emit('cancel')"
          >
            <i class="fas fa-chevron-left" />
          </div>
          <span v-if="hasTitleSlot" @mousedown.stop>
            <slot name="title" />
          </span>
          <span v-else>{{ title }}</span>
          <div
            v-if="hasClose"
            class="closeBtn iconBtn"
            data-test-id="modalCloseIcon"
            @mousedown.stop="close"
          >
            <i class="fas fa-times" />
          </div>
        </div>
        <div
          :id="contentId"
          ref="content"
          class="content tw-hide-scrollbar"
          :class="[
            { noContentPadding, [contentClass]: true, forceMobile },
            modalId,
          ]"
          :style="contentStyle"
          @click.stop
        >
          <slot />
          <div v-if="bottomFiller" class="tw-h-32" />
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.maskPopup:not(.noFloat) {
  @apply tw-fixed tw-left-0 tw-top-0 tw-flex tw-h-screen tw-w-screen tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50;

  z-index: 99999;

  .wrapper,
  .largePad768plus .wrapper {
    @apply tw-h-screen tw-w-screen tw-rounded-none;

    &:not(.forceMobile) {
      @screen sm {
        width: var(--grid-4);
        height: unset;
      }
    }
  }

  .largePad768plus .wrapper:not(.forceMobile) {
    @screen md {
      width: var(--grid-6);
    }
  }

  .wrapper {
    @apply tw-relative tw-rounded-xs tw-bg-white;

    &:not(.forceMobile) {
      @screen md {
        width: var(--grid-6);
      }
    }
  }

  .content {
    @apply tw-overflow-y-auto;

    max-height: calc(100vh - 64px);

    @screen sm {
      &:not(.forceMobile) {
        max-height: calc(90vh - 64px);
      }
    }
  }

  &.hasTitle .content {
    max-height: calc(100vh - 74px);

    @screen 375 {
      max-height: calc(100vh - 79px);
    }

    @screen sm {
      max-height: calc(90vh - 64px - 73px);
    }
  }
}

.wrapper {
  .content {
    @apply tw-p-6 tw-pb-0;

    &.noContentPadding {
      @apply tw-p-0;
    }
  }
}

.noFloat {
  .title {
    @apply tw-border-0 tw-bg-white tw-shadow;

    @screen sm {
      @apply tw-border-b tw-shadow-none;
    }
  }
}

.title {
  @apply tw-relative tw-w-full tw-border-gray-300 tw-p-6 tw-text-center tw-text-lg tw-shadow;

  @screen sm {
    @apply tw-px-8;
  }
}

.wrapper {
  &:not(.hasTitle) {
    & > .title {
      @apply tw-h-0 tw-w-full tw-border-none tw-text-center;
    }

    .iconBtn {
      height: unset;
    }
    .closeBtn {
      top: 24px;
    }
  }
}

.title,
.title > span {
  @apply tw-inline-block tw-font-sans tw-text-xl tw-font-bold;

  @screen 375 {
    @apply tw-text-2xl;
  }
}

.iconBtn {
  @apply tw-absolute tw-top-0 tw-flex tw-h-full tw-cursor-pointer tw-items-center tw-justify-center;

  i {
    @apply tw-text-xl tw-text-gray-400;
  }
}

.closeBtn {
  @apply tw-cursor-pointer;

  right: 24px;
}

.previousButton {
  left: 24px;

  @screen xl {
    left: 40px;
  }
}
</style>
